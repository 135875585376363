@import "styles/mixins/index";
.pharmacy-section {
    margin-bottom: 20px;
    .contact-content {
        margin-top: 10px;
        .form-wrapper {
            .form-label {
                display: block;
                margin-bottom: 5px;
                text-transform: capitalize;
                span {
                    border: none;
                    color: #ff4136;
                    font-size: 0.9rem;
                }
            }
            margin-bottom: 10px;
        }
        .form-main {
            margin-bottom: 10px;
            span {
                border: none;
                color: #ff4136;
                font-size: 0.9rem;
            }
            .form-wrapper-main {
                display: flex;
                align-items: center;
                gap: 10px;
                @media (max-width: 991px) {
                    display: block;
                }
                .form-wrapper-wrap {
                    @media (max-width: 991px) {
                        margin-bottom: 5px;
                    }
                    @media (min-width: 991px) {
                        width: 50%;
                    }
                    .form-label-sub {
                        display: block;
                        text-transform: capitalize;
                        font-size: 14px;
                        margin-top: 2px;
                    }
                    input {
                        width: 100%;
                    }
                }
            }
        }
        a {
            color: #0d6efd;
            font-size: 14px;
            text-decoration: underline;
            margin-bottom: 10px;
            display: block;
        }
        select {
            width: 100%;
            outline: none;
            position: relative;
            background-color: #fff;
            font-size: 17px;
            appearance: none;
            height: 49px;
            color: #757575;
            padding: 14px 12px;
            border: 2px #dddddd solid;
            background-position: right 10px center;
            background-repeat: no-repeat;
            background-size: auto 50%;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23212121'%3E%3Cpath d='M24 24H0V0h24v24z' fill='none' opacity='1'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z'/%3E%3C/svg%3E");
            @media (min-width: 991px) {
                width: 50%;
            }
        }
        textarea,
        input {
            padding: 14px 12px;
            border: 2px #dddddd solid;
            font-size: 17px;
            width: 100%;
            font-family: var(--text-font-family);
            @media (min-width: 991px) {
                width: 50%;
            }
        }
        .form-number-wrap {
            input {
                width: 100%;
            }
        }
    }
    .signup-btn {
        padding: 16px 35px;
        background-color: var(--primary-color);
        color: var(--bg-white);
        border-radius: calc(5px - 0px);
        border: none;
        font-size: 18px;
        margin: 0;
        line-height: 28px;
        border-radius: 50px;
        font-weight: 500;
        width: 20%;
        transition: all 0.5s ease-in-out;
        margin-top: 20px;
        @media (max-width: 991px) {
            width: 40%;
        }
        @media (max-width: 480px) {
            width: 50%;
        }
    }
    p {
        margin-bottom: 5px;
        span {
            color: #ff0000;
        }
    }
    .prescription-nub-wrap {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
    }
    .minus-iocn,
    .add-iocn {
        cursor: pointer;
        svg {
            height: 25px;
            width: 25px;
        }
    }
}

.submission-message {
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
}
.submission-message.success {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
}
.submission-message.error {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
}